<template lang="pug">
form( @submit="onSubmit" @keydown.esc="onCancel")
    .modal-header
      h2( v-if="link.id")
        | {{translate('admin.menus.link.edit')}}
      h2( v-if="!link.id")
        | {{translate('admin.menus.link.new')}}
      .icon-delete( @click="closeModal")

    .modal-content
      h3
        | {{translate('admin.menus.link.link_details')}}

      .field
        helpers-label.label(field="link.name")
        input( name="link.name" v-model="link.name" :class="{invalid: isInvalid('link.name')}")
        helpers-validation-message.error(:errors="validation" field="link.name")

      .field
        helpers-label.label(field="link.handle")
        input( name="link.handle" v-model="link.handle" :class="{invalid: isInvalid('link.handle')}" @keyup="stopGenHandle")
        helpers-validation-message.error(:errors="validation" field="link.handle")

      .field
        helpers-label.label(field="link.parent_id")
        .select.fancy-select( @click="openDropDownMenu")
          .fake-input( data="fake-name" @click="openDropDownMenu" v-text="link.parent_id ? attr_by_key(links.findById(link.parent_id), 'name') : translate('admin.shared.autocomplete.no_parent')")
          ul.custom-dropdown
            li( v-for="item in parent_options()" v-text="item.text || item.value" :class="{active: link.parent_id === item.value}" @click="link.parent_id=item.value")

      .field( v-if="links_config.length")
        helpers-label.label(field="link.style")
        .select.fancy-select( @click="openDropDownMenu")
          .fake-input( data="fake-name" @click="openDropDownMenu" v-text="getStyleName")
          ul.custom-dropdown
            li( v-for="item in links_config" v-text="item.name" :class="{active: link.style === item.style}" @click="switchStyle(item.style)")
        helpers-validation-message.error(:errors="validation" field="link.style")

      .hidden
        helpers-label.label(field="link.entity_type")
        input( name="link.entity_type" class="fluid" v-model="link.entity_type" type="text")

      .field( v-if="show_value_input")
        helpers-label.label(field="link.value")
        input( name="link.value" class="fluid" v-model="link.value" type="text" :class="{invalid: isInvalid('link.value')}")
        helpers-validation-message.error(:errors="validation" field="link.value")

      .field( v-if="show_entity_id_input")
        helpers-label.label(field="link.entity_id")
        select-autocomplete(v-model="entity_id_obj" :collection="results")
        helpers-validation-message.error(:errors="validation" field="link.entity_id")


    .modal-footer(v-if="link.id")
      .left
        .button.delete.inverted.short( @click="deleteConfirm")
          | {{translate('admin.buttons.delete')}}
      .right
        button.button.primary.short( type="submit" @click="onSubmit")
          | {{translate('admin.buttons.save')}}
    .modal-footer( v-if="!link.id")
      .left
        .button.secondary.inverted.short( @click="closeModal")
          | {{translate('admin.buttons.cancel')}}
      .right
        button.button.primary.short( type="submit" @click="onSubmit")
          | {{translate('admin.link.new.add_link')}}
</template>

<script>
import getSlug from 'speakingurl'
import Root from '@/admin/mixins/root_computed.js'
export default{
  mixins: [Root],
  data() {
    return {
      links_config: [],
      link: {name: null, parent_id: null, style: null},
      links: [],
      handle_was_changed: false,
      links_config_name: {},
      links_match: {
        'Product': 'products',
        'BlogPost': 'blog_posts',
        'Category': 'categories',
        'Collection': 'collections',
        'Page': 'pages',
        'Brand': 'vendors'
      },
      results: [],
      entity_id_obj: null,
    }
  },
  created(){
    this.fetch_cached_collection("links/config").then(this.search)
    this.link.parent_id || (this.link.parent_id = null)

    this.$watch("link.name", this.updateHandle, {deep: true})
    this.$watch("link.id", ((value)=>{if (value) this.handle_was_changed=true}), {deep: true})
    this.$watch("link.handle", (value)=>{
      if (value && value.length === 0)
        this.handle_was_changed= false
      else if (value && !value.endsWith("-"))
        this.$nextTick( _=> this.link.handle = getSlug(value))
      }, {deep: true}
    )
    this.$watch( "entity_id_obj", val=> {
      if (val) this.link.entity_id = val.id
    })
  },

  mounted(){
    this.$nextTick( _=>{
      this.link.old_entity_label = this.link.entity_label
      window.addEventListener('click', this.onClick)
    })
  },

  beforeUnmount(){
    window.removeEventListener('click', this.onClick, false)
  },

  methods: {
    onSubmit(e){
      e.preventDefault()
      this.clean_all_validations(this)

      this.link.id ? this.onUpdate(e) : this.onCreate(e)
    },
    onUpdate(e){
      this.axios.patch(`/links/${this.link.id}`, {link: this.link}).then(response=>{
        this.closeModal()
        const i = this.links.findIndex(this.link)
        this.links[i] = this.link = response.data
        this.refresh(`/menus/${this.link.menu_id}/edit`)
      }).catch((resp) => this.set_validation2(this, "link", resp))
    },

    onCreate(e){
      this.axios.post("/links", {link: this.link}).then(response=>{
        this.closeModal()
        this.links.push(response.data)
        this.refresh( `/menus/${response.data.menu_id}/edit`)
      }).catch(resp=>this.set_validation2(this, "link", resp))
    },

    onCancel(e){
      e.preventDefault()
      this.closeModal()
    },

    deleteConfirm(e){
      e.preventDefault()
      this.modal = null
      this.modal_delete = {type: "link", yes_function: this.onDestroy}
    },

    onDestroy(e){
      e.preventDefault()
      this.axios.delete(`links/${this.link.id}`).then(()=>{
        this.closeModal()
        this.refresh( `/menus/${this.link.menu_id}/edit`)
      })
    },
    onClick(){this.link.entity_label = this.link.old_entity_label},

    search(){
      this.results = []
      if (this.show_entity_id_input){
        this.fetch_cached_collection_all(this.links_match[this.link.entity_type]).then(coll=>{
          this.results = coll
          this.entity_id_obj = coll.findById(this.link.entity_id)
        })
      }
    },

    setEntityId(re) {
      this.link.entity_id = re.id
      this.link.entity_label = re.name

      this.link.old_entity_label = this.link.entity_label
      this.results = []
    },


    childrenOf(parent) {
      return this.links.filter((item) => item.parent_id === parent.id).sort((a, b)=> a.position - b.position)
    },

    parent_options(options = [], list = this.roots, level = 0){
      if (options.length === 0)
        options.push({text: I18n.t('admin.shared.autocomplete.no_parent'), value: ''})

      this.each(list, (item) =>{
        if (this.link.id !== item.id) options.push({text: `${Array(level).join("--")} ${item.name}`, value: item.id})
        this.parent_options(options, this.childrenOf(item), level + 2)
      })
      return options
    },


    switchStyle(value){
      this.$set(this.link, 'style', value)
      // reset entity
      this.$set(this.link, 'entity_id', '')
      this.$set(this.link, 'entity_label', '')
      this.$set(this.link, 'entity_type', null)
      for (const e of this.links_config) {
        if (e.style === this.link.style && e.config && e.config.entity_type)
          this.$set(this.link, 'entity_type', e.config.entity_type)
      }
      this.search()
    },

    stopGenHandle() {
      this.handle_was_changed = true
    },

    updateHandle(value) {
      if (!this.handle_was_changed && !this.link.id)
        this.link.handle = getSlug(value)
    }
  },
  computed: {
    roots() {
      return this.links.filter((item) =>!item.parent_id).sort((a, b)=> a.lft - b.lft)
    },

    // logic may be extracted to API config after migration
    show_value_input(){
      return this.link.style === 'CUSTOM'
    },

    show_entity_id_input() {
      for (const e of this.links_config){
        if (e.style === this.link.style && e.config && e.config.entity_type)
          return true
      }
      return false
    },

    getStyleName(){
      for(const e of this.links_config) {
        if (e.style === this.link.style) return e.name
      }
      return
    },
  }
}
</script>
