/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
export default {
  methods: {
    buildSubmitData() {
      const data = {};

      data.possible_languages = this.current_shop.possible_languages;
      if (this.active) {
        data.possible_languages.push(this.language.code);
      } else {
        data.possible_languages.$remove(this.language.code);
      }
      data.possible_languages = [...new Set(data.possible_languages)];

      data.published_languages = this.current_shop.published_languages;
      if (this.published && this.active) {
        data.published_languages.push(this.language.code);
      } else {
        data.published_languages.$remove(this.language.code);
      }
      data.published_languages = [...new Set(data.published_languages)];

      data.language_id = this.primary && this.published && this.active ?
        this.language.id
      :
        this.current_shop.language_id;

      return data;
    },

    performAction() {
      this.loaded=false;
      return this.axios.patch("shop", {shop: this.buildSubmitData()}).then(this.onSuccess);
    },

    onSuccess(shop) {
      this.current_shop = shop.data;
      Cache.deleteBy("translations");
      this.closeModal();
      this.loaded = false;
      this.refresh("/translations");
    }
  }
}