<template lang="pug">
form( @submit="onSubmit" @keydown.esc="onCancel")
    template( v-if="ready")
      .modal-header
        h2
          | {{translate('admin.products.seo.seo_settings' )}}
        .icon-delete( @click="closeModal")

      .modal-content
        .field
          helpers-label.label(field="product.meta_title")
          input( name="product.name" v-model="product.meta_title" :class="{invalid: isInvalid('product.meta_title')}")
          helpers-validation-message.error(:errors="validation" field="product.meta_title")

        .field
          helpers-label.label(field="product.meta_description")
          textarea( name="product.meta_description" v-model="product.meta_description" :class="{invalid: isInvalid('product.meta_description')}")
          helpers-validation-message.error(:errors="validation" field="product.meta_description")

        .field
          .general-handle
            .field.same-row-elements
              .input
                .fake-placeholder( style="white-space: nowrap;" @click="focusPermalink")
                  | https://{{current_shop.external_hostname}}/products/
                input( name="product.permalink" v-model="product.permalink" :class="{invalid: isInvalid('product.permalink')}" style="display: inline-block;" ref="handle_permalink")
                .fake-input
                helpers-validation-message.error(:errors="validation" field="product.permalink")

      .modal-footer
        .left
          .button.secondary.inverted.short( @click="closeModal")
            | {{translate('admin.buttons.cancel')}}
        .right
          button.button.primary.short( type="submit" @click="onSubmit")
            | {{translate('admin.buttons.save')}}

</template>
<script>/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import Root from '@/admin/mixins/root_computed.js';
export default{
  mixins: [Root],
  data() {
    return {
      static: null,
      product: null,
      ready: false
    };
  },

  validations: {
    "product.permalink": "required, maxLength: 255"
  },

  methods: {
    focusPermalink() {
      return this.$refs.handle_permalink.focus();
    },

    data_synced() {
      return this.ready = true;
    },

    onCancel() { return this.closeModal(); },

    updateParent() {
      this.$set(this.static, "meta_title", this.product.meta_title);
      this.$set(this.static, "permalink", this.product.permalink);
      return this.$set(this.static, "meta_description", this.product.meta_description);
    },

    onSubmit(e) {
      e?.preventDefault();
      if (this.static.id) {
        return this.axios.patch( `/products/${this.static.id}`, {product: this.product}).then(response => {
          this.updateParent();
          return this.closeModal();
        }
        , response => this.set_validation2(this, "product", response));
      } else {
        this.updateParent();
        return this.closeModal();
      }
    }
  }

}
</script>
